
$primaryColor: #7555f8;
$textColor: #2a2e34;
$linkCOlor: #6f7378;
@tailwind base;
@tailwind components;
@tailwind utilities;


body {
  color: $textColor !important;
  font-weight: 400;
  font-size: 0.75rem;
}
a{
  color: $linkCOlor;
}
.dashBoardLayout {
    padding-left: 10rem;
  }
  .newTaskSmall {
    background-color: #efeefe;
    font-size: 12px;
    padding: 4px 12px;
    color: $primaryColor;
    border-radius: 0px 10px 10px 10px;
    transition: 0.3s;
  }

  .newTaskSmall:hover {
    color: #efeefe;
    background-color: $primaryColor;
    
  }